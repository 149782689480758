import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

import { FaRegBookmark } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";

const Card = ({ data, onViewProfile }) => {
  let {
    avatar_url,
    name,
    total_expenses,
    join_date,
    address,
    category_name,
    category_color,
    id,
    to_be_sponsor,
  } = data;

  const formatName = (name) => {
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  const navigate = useNavigate();

  return (
    <div className="card mb-3">
      <div class="card-header">
        <FiShare2 className="share-icon" />
        <FaRegBookmark className="bookmark-icon" />
      </div>
      <img
        className="profile-pic"
        src={avatar_url || "./child/noImg.png"}
        alt=""
      />
      {/* {to_be_sponsor && <span className="ribbon">Priority</span>} */}
      <div className="card-body">
        <div className="d-flex align-items-center">
          <div className="name">{formatName(name)}</div>
          <div className="ms-auto" style={{ fontSize: 13 }}>
            Expenses <b>₹{total_expenses}</b>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div
            className="view-profile"
            style={{ color: category_color, cursor: "pointer" }}
            onClick={() => onViewProfile(id)}
          >
            View Profile
          </div>
          <div
            className="ms-auto btn"
            onClick={() => navigate(`/checkout/${id}`)}
          >
            Sponser
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
