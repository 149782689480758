import React from "react";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        {/* Logo Section */}
        <div
          className="footer-section logo-section"
          onClick={() => (window.location.href = "https://shaagird.org/")}
        >
          <img src="/logo.png" alt="Shaagird Logo" className="footer-logo" />
        </div>

        {/* Links Section 2 */}
        <div className="footer-section links-section2">
          <ul>
            <li
              onClick={() =>
                (window.location.href =
                  "https://shaagird.org/terms-conditions/")
              }
            >
              Terms and Conditions
            </li>
            <li
              onClick={() =>
                (window.location.href = "https://shaagird.org/refund-policy/")
              }
            >
              Refund / Cancellation
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://shaagird.org/Policies/Data_Protection_Policy_Shaagird.pdf")
              }
            >
              Data Protection Policy
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://shaagird.org/Policies/Privacy_Policy_Shaagird.pdf")
              }
            >
              Privacy Policy
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://shaagird.org/Policies/DRD_Policy_Shaagird.pdf ")
              }
            >
              Document Retention & Destruction Policy
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://shaagird.org/Policies/Child_Protection_Policy_Shaagird.pdf ")
              }
            >
              Child Protection Policy
            </li>
            <li
              onClick={() =>
                (window.location.href = "https://shaagird.org/contact-us/ ")
              }
            >
              Contact
            </li>
          </ul>
        </div>
        {/* Links Section 1 */}
        <div className="footer-section links-section">
          <ul>
            <li
              onClick={() =>
                (window.location.href =
                  "https://shaagird.org/Policies/POSH_Policy_Shaagird.pdf ")
              }
            >
              POSH Policy
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://shaagird.org/Policies/Whistleblower_Policy_Shaagird.pdf  ")
              }
            >
              Whistleblower Policy
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://shaagird.org/Policies/Conflict_of_Interest_Policy_Shaagird.pdf")
              }
            >
              Conflict of Interest
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://shaagird.org/Policies/Grievance_Redressal_Policy_Shaagird.pdf ")
              }
            >
              Grievance Redressal Policy
            </li>
            <li onClick={() => (window.location.href = "/all-kids")}>
              Sponsor Kids
            </li>
            <li onClick={() => (window.location.href = "/sponsored-kids")}>
              Sponsored Kids
            </li>
            <li
              onClick={() =>
                (window.location.href = "https://donate.shaagird.org/")
              }
            >
              Donate
            </li>
          </ul>
        </div>
        {/* Contact Section */}
        <div className="footer-section contact-section">
          <h3>Contact Us</h3>
          <div className="contact-item">
            <img src="/img/mail.png" alt="Email" className="contact-icon" />
            <span>contact@shaagird.org</span>
          </div>
          <div className="contact-item">
            <img
              src="/img/call.png" // Replace with your phone icon URL
              alt="Phone"
              className="contact-icon"
            />
            <span>+91-9319986960</span>
          </div>
        </div>
        {/* Social Section */}
        <div className="footer-section social-section">
          <div className="social-item">
            <img
              src="/img/instagram.png"
              alt="Instagram"
              className="social-icon"
            />
            <span>
              <a
                href="https://www.instagram.com/shaagirdfoundation/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </span>
          </div>
          <div className="social-item">
            <img src="/img/twitter.png" alt="Twitter" className="social-icon" />
            <span>
              <a
                href="https://x.com/Shaagird__"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
            </span>
          </div>
          <div className="social-item">
            <img
              src="/img/facebook.png"
              alt="Facebook"
              className="social-icon"
            />
            <span>
              <a
                href="https://www.facebook.com/people/Shaagird-Foundation/61556481338080/?mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </span>
          </div>
          <div className="social-item">
            <img src="/img/youtube.png" alt="YouTube" className="social-icon" />
            <span>
              <a
                href="https://www.youtube.com/@ShaagirdFoundation"
                target="_blank"
                rel="noopener noreferrer"
              >
                Youtube
              </a>
            </span>
          </div>
          <div className="social-item">
            <img
              src="/img/linkedin.png"
              alt="LinkedIn"
              className="social-icon"
            />
            <span>
              <a
                href="https://www.linkedin.com/in/shaagird-foundation-9ba8182b7/?trk=public_post-text&originalSubdomain=in"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
