import React from "react";
import { Link, useNavigate } from "react-router-dom";

import "swiper/css";
import "swiper/css/effect-coverflow";

const Banner = () => {
  const navigate = useNavigate();

  const sponsorChild = () => {
    navigate("/all-kids");
  };
  return (
    <section className="banner flex">
      {/* Left Side Text */}
      <div className="banner-text">
        <h1>
          Empower by <br></br> Education
        </h1>
        <p>"Ignite Potential, Transform Futures"</p>
        <button className="cta-btn btn" onClick={sponsorChild}>
          Sponsor a Child
        </button>
      </div>

      {/* Right Side Image */}
      <div className="banner-image">
        <div className="circle">
          <img
            src="/img/banner-circular.png"
            className="circle-img"
            alt="Banner-circle"
          />

          <div className="hero-child">
            <img src="/child/banner/hero-child.png" alt="Child" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
