import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { VscChromeClose } from "react-icons/vsc";
import moment from "moment";
import ErrorMessage from "../../components/ErrorMessage";

// Import components and services
import Card from "./components/Card";
import Category from "./components/Category";
import Banner from "./components/Banner";
import Loader from "../../components/Loader";
import SponseredKidCard from "../sponseredKids/components/Card";
import KidService from "../../services/kids";
import CategoryService from "../../services/categories";
import SponsoredService from "../../services/sponsored";
import decodeToken from "../../utils/decodeToken";
import PerformerKids from "./PerformerKids";

// Import Swiper and styles
import "swiper/css";
import "swiper/css/navigation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const initialPerformerJsonData = [
  {
    id: 1,
    enrollment_id: "21R005",
    avatar: "/child/performer/Kareema.jpeg",
    name: "Kareema",
    story:
      "Kareema, a girl who lost her father to the Delhi pogrom in 2020, became a part of our school after the tragedy. Devastated she was when she entered, but today, she is one of the toppers of the class. She exudes confidence and determination. Kareema secured the highest marks in 6th grade and showed exemplary performance in extracurricular activities. Shaagird Foundation is proud of her.",
  },
  {
    id: 2,
    enrollment_id: "21R019",

    avatar: "/child/performer/Madiha.jpeg",
    name: "Madiha",
    story:
      "Financial constraints compelled Madiha to drop out from school, but it could not take away the determination and passion to study from her. Seeing her love for studies, we enrolled her in Sunrise Public School. We are pleased to announce that in the previous academic year, Madiha secured the highest marks. Shaagird Foundation is proud of her.",
  },
  {
    id: 3,
    enrollment_id: "24BU016",

    avatar: "/child/performer/Mushfiqur.jpeg",
    name: "Mushfiqur",
    story:
      "Mushfiqur, a dedicated student of our madrassa, is showing outstanding performance in his Hifz journey. Starting his journey from scratch, Mushfiqur is now learning the Quran with full dedication and love. Shaagird Foundation is proud to have Mushfiqur.",
  },
  {
    id: 4,
    enrollment_id: "22KS024",

    avatar: "/child/performer/Babar.jpeg",
    name: "Babar",
    story:
      "Babar, a dedicated student at our learning center, excels in both spiritual and academic fields. His remarkable performance in all subjects sets him apart each year, consistently securing top marks. Shaagird Foundation takes great pride in having him as part of our educational journey, showcasing his commitment to excellence and hard work.",
  },
];

// Refactored SponsorKids component
const SponsorKids = () => {
  const navigate = useNavigate();
  const { user_id } = decodeToken();

  const [activeIndex, setActiveIndex] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [kids, setKids] = useState([]);
  const [states, setStates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sponsoredKids, setSponsoredKids] = useState([]);
  const [filterSponserKidsData, setFilterSponserKidsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [stateVal, setStateVal] = useState("");
  const [genderVal, setGenderVal] = useState("");
  const [budgetVal, setBudgetVal] = useState(0);
  const [categoryId, setCategoryId] = useState(null);
  const [daysLeft, setDaysLeft] = useState();
  const [performerJsonData, setPerformerJsonData] = useState(
    initialPerformerJsonData
  );

  useEffect(() => {
    getAllKids();
    getAllCategories();
    getAllSponsoredKids();
  }, []);

  const getAllKids = async () => {
    try {
      const { data } = await KidService.fetchKids();
      setKids(data.data.filter((k) => !k.is_sponsored));
      setStates([...new Set(data.data.map((s) => s.state))]);
      setFilterSponserKidsData(data.data.filter((k) => !k.is_sponsored));
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const getAllCategories = async () => {
    try {
      const { data } = await CategoryService.fetchCategories();
      setCategories(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getAllSponsoredKids = async () => {
    try {
      const { data } = await SponsoredService.fetchSponsorKidsByMentor(user_id);
      const uniqueKids = [
        ...new Map(data.data.map((d) => [d["kid_id"], d])).values(),
      ];
      setSponsoredKids(uniqueKids.filter((k) => !k.to_be_sponsor));

      setDaysLeft(
        uniqueKids.filter(
          (d) => moment(d.mentoring_left_date).diff(moment(), "days") > 30
        )
      );

      setLoading(false);
    } catch (err) {
      setSponsoredKids([]);
      console.error(err);
    }
  };

  const onCategory = (e, category_id, index) => {
    if (category_id) {
      setActiveIndex(index);
      setFilterSponserKidsData(
        kids.filter(
          (d) =>
            d.category_id === category_id &&
            (genderVal ? d.gender === genderVal : true) &&
            (stateVal ? d.state === stateVal : true) &&
            (budgetVal ? budgetVal >= d.total_expenses : true)
        )
      );
      setCategoryId(category_id);
    } else {
      setActiveIndex(null);
      setFilterSponserKidsData(kids);
      setCategoryId(null);
      setShowFilter(false);
    }
  };

  const onViewProfile = (id) => {
    navigate(`/kid/${id}`);
  };

  const onChangeFilter = (e) => {
    const { value, name } = e.target;
    let newFilterData = [...kids];

    if (name === "gender") setGenderVal(value);
    if (name === "state") setStateVal(value);
    if (name === "budget") setBudgetVal(value);

    if (name) {
      newFilterData = newFilterData.filter(
        (k) =>
          (name === "gender" ? k.gender === value : true) &&
          (name === "state" ? k.state === value : true) &&
          (name === "budget" ? parseInt(value) >= k.total_expenses : true) &&
          (categoryId ? k.category_id === categoryId : true)
      );
    }

    setFilterSponserKidsData(newFilterData);
  };

  // Arrow components for Swiper
  const ArrowLeft = (props) => (
    <button {...props} className="slick-prev">
      &#8249;
    </button>
  );
  const ArrowRight = (props) => (
    <button {...props} className="slick-next">
      &#8250;
    </button>
  );

  const settings = {
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    slidesToShow: window.innerWidth < 768 ? 1 : 5,
    slidesToScroll: window.innerWidth < 768 ? 1 : 5,
    centerMode: true,
    centerPadding: "0",
  };
  const prioritySettings = {
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    slidesToShow: window.innerWidth < 768 ? 1 : 5,
    slidesToScroll: window.innerWidth < 768 ? 1 : 5,
    centerMode: true,
    centerPadding: "0",
    // rows: 2,
  };

  const performersettings = {
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    slidesToShow: window.innerWidth < 768 ? 1 : 2,
    slidesToScroll: window.innerWidth < 768 ? 1 : 2,
    centerMode: true,
    centerPadding: "0",
    // rows: 1,
  };

  const handleViewAllKids = () => {
    navigate("/all-kids"); // Navigate to the /sponsored-kids route
  };
  useEffect(() => {
    if (kids.length > 0) {
      const updatedPerformerData = performerJsonData.map((performer) => {
        // Find the kid by matching name
        const matchedKid = kids.find(
          (kid) =>
            kid.name === performer.name &&
            kid.enrollment_id === performer.enrollment_id
        );
        if (matchedKid) {
          // Add the kid id to the performer data
          return { ...performer, kidId: matchedKid.id };
        }
        return performer;
      });

      setPerformerJsonData(updatedPerformerData);
    }
  }, [kids]);
  return (
    <div className="KidsContent">
      <Banner />
      {loading ? (
        <Loader />
      ) : (
        <>
          <section className="category-container mt-20">
            <div className="container">
              <h4 className="text-align-center">Explore Profile</h4>
              {/* <h3 className="text-align-center">Our Categories</h3> */}

              <Row>
                <Category
                  activeIndex={activeIndex}
                  kidsData={kids}
                  onCategoryHandler={onCategory}
                  categoryData={categories}
                />
              </Row>
            </div>
          </section>

          <section className="child-container mt-20">
            <div className="container">
              <h3 className="text-align-center">Our Children's</h3>
              <div className="slider-container">
                {filterSponserKidsData.length > 0 ? (
                  <>
                    <Slider {...settings}>
                      {filterSponserKidsData.map((data) => (
                        <div
                          className={` ${showFilter ? "col-sm-4" : "col-sm-3"}`}
                        >
                          <Card onViewProfile={onViewProfile} data={data} />
                        </div>
                      ))}
                    </Slider>
                    <button
                      className="btn btn-center"
                      onClick={handleViewAllKids}
                    >
                      View All
                    </button>
                  </>
                ) : (
                  <ErrorMessage noData={"In this Category"} />
                )}
              </div>
            </div>
          </section>

          <section className="child-priority-container mt-20">
            <div className="container">
              <h4 className="text-align-center">Our Priority</h4>
              {/* <h3 className="text-align-center">Building Brighter Futures</h3> */}
              <Slider {...prioritySettings}>
                {sponsoredKids.length > 0 ? (
                  sponsoredKids.map(
                    (kid) =>
                      moment(kid.mentoring_left_date).diff(moment(), "days") <
                        30 && (
                        <div
                          className={`${showFilter ? "col-sm-4" : "col-sm-3"}`}
                        >
                          <SponseredKidCard
                            user_id={user_id}
                            onViewProfile={onViewProfile}
                            data={kid}
                          />
                        </div>
                      )
                  )
                ) : (
                  <h5 className="alert alert-danger text-center">
                    No Sponsored Kid Found
                  </h5>
                )}
              </Slider>
            </div>
          </section>

          <section className="performer-of-the-year mt-20">
            <div className="container">
              <div className="performerHeading mt-20">
                <h4 className="text-align-center">Performer Of The Year</h4>
              </div>
              <Slider {...performersettings}>
                {performerJsonData.map((data) => (
                  <PerformerKids
                    key={data.id}
                    data={data}
                    onViewProfile={onViewProfile}
                  />
                ))}
              </Slider>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default SponsorKids;
