import React from "react";
import { FaRegBookmark } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";

const PerformerKids = ({ data, onViewProfile }) => {
  return (
    <div className="performer-container">
      {console.log("qqqqqq---- new", data)}
      <div className="performer-card" key={data.id}>
        <div className="performer-image">
          <img src={data.avatar} alt={data.name} className="profile-pic" />
        </div>
        <div className="performer-info">
          <h2 className="performer-name">{data.name}</h2>
          <p className="performer-description">{data.story}</p>
          <button
            className="sponsor-button"
            onClick={() => onViewProfile(data.kidId)}
          >
            Sponsor
          </button>
        </div>
        <div className="icons">
          <FiShare2 className="icon" />
          <FaRegBookmark className="icon" />
        </div>
      </div>
    </div>
  );
};

export default PerformerKids;
