import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import KidService from "../../services/kids";
import { useNavigate } from "react-router-dom";
import decodeToken from "../../utils/decodeToken";
import onRazorpay from "./RazorPay";
import NoImg from "../../assets/no-image.png";
import moment from "moment";

import { toast } from "react-toastify";

const Checkout = () => {
  const navigate = useNavigate();
  const [kid, setKid] = useState({});
  const [months, setMonths] = useState(1);
  const { user_id, full_name, email } = decodeToken();
  let { kid_id } = useParams();

  useEffect(() => {
    getKid();
  }, []);

  let getKid = async () => {
    try {
      const { data } = await KidService.fetchKidById(kid_id);
      setKid(data.data[0]);
    } catch (err) {}
  };

  let totalAmt = months * kid.total_expenses;

  let sponsorData = {
    kid_id: kid_id,
    kid_name: kid.name,
    amount: totalAmt,
    mentor_id: parseInt(user_id),
    for_months: months,
    is_active: true,
    category_name: kid.category_name,
    state: kid.state,
    category_id: kid.category_id,
    mentor_name: full_name,
    email: email,
    is_anonymous: user_id ? false : true,
  };

  const handlePayment = async () => {
    if (!user_id) {
      navigate({
        pathname: `/anonymous/${sponsorData.kid_id}`,
        search: `?for_months=${months}`,
      });
    } else {
      onRazorpay(sponsorData);
    }
  };

  return (
    <>
      <div className="profile-bg">
        <img src="/img/profile-bg.png" alt="profile-bg" />
      </div>
      <div className="container my-3">
        <h3 className="heading text-align-center">
          Please Sponsered {kid.name}
        </h3>
        <div className="card">
          <div className="card-body">
            <div className="row">
              {/* Left Side: Profile Image and Info */}
              <div className="col-lg-3 col-md-4 col-sm-12 text-center mb-4 mb-sm-0">
                <div className="profile-section">
                  <img
                    src={kid.avatar_url || NoImg}
                    alt="Profile"
                    className="profile-card-pic rounded-circle"
                    width="150"
                    height="150"
                  />
                  <div className="profile-info mt-3">
                    <h5 className="text-capitalize">{kid.name}</h5>
                    <p>
                      <strong>Category: </strong>
                      {kid.category_name}
                    </p>
                    <p>
                      <strong>Monthly expense for {kid.name}: </strong>
                      <b>
                        ₹
                        {kid.total_expenses
                          ? kid.total_expenses
                          : kid.basic_amount}
                      </b>
                    </p>
                  </div>
                </div>
              </div>

              {/* Middle: Select Month */}
              <div className="col-lg-5 col-md-4 col-sm-12 d-flex flex-column justify-content-center align-items-center mb-4 mb-sm-0">
                <h5 className="text-muted">Select Sponsoring Duration</h5>
                <select
                  className="form-control mb-3"
                  style={{ width: "80%" }}
                  value={months}
                  onChange={(e) => setMonths(e.target.value)}
                >
                  <option value={1}>1 Month</option>
                  <option value={2}>2 Months</option>
                  <option value={3}>3 Months</option>
                  <option value={4}>4 Months</option>
                  <option value={5}>5 Months</option>
                  <option value={6}>6 Months</option>
                  <option value={7}>7 Months</option>
                  <option value={8}>8 Months</option>
                  <option value={9}>9 Months</option>
                  <option value={10}>10 Months</option>
                  <option value={11}>11 Months</option>
                  <option value={12}>12 Months</option>
                </select>
              </div>

              {/* Right Side: Checkout Button */}
              <div className="col-lg-4 col-md-4 col-sm-12 d-flex flex-column justify-content-center align-items-center">
                <div className="mb-3">
                  <span className="text-muted">Total Sponsoring Amount:</span>
                  <b> ₹{totalAmt ? totalAmt : sponsorData.amount}</b>
                </div>
                <button
                  className="btn"
                  style={{ width: "80%" }}
                  onClick={() => handlePayment()}
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
