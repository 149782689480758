import React, { useEffect, useState } from "react";
import Card from "./components/Card";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import KidService from "../../services/kids";
import ExpencesService from "../../services/expences";
import Loader from "../../components/Loader";
import ErrorMessage from "../../components/ErrorMessage";
import { toast } from "react-toastify";

import "./Profile.css";

import moment from "moment";
import decodeToken from "../../utils/decodeToken";

const KidProfile = () => {
  const [profile, setProfile] = useState({});
  const [expences, setExpences] = useState([]);
  const [kidPhotos, setKidPhotos] = useState([]);
  const [kidDocuments, setKidDocuments] = useState([]);
  const [isImgFull, setIsImgFull] = useState("");
  const [loading, setLoading] = useState(true);

  const { user_id, super_admin_id } = decodeToken();
  let params = new URLSearchParams(window?.location?.search);
  const isSponsoredKid = params?.get("isSponsored");

  let { kid_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getKid();
    getKidExpences();
  }, []);

  useEffect(() => {
    if ((user_id || super_admin_id) && profile.enrollment_id) {
      getKidPhotos();
      getKidDocuments();
    }
  }, [profile.enrollment_id]);

  let getKid = async () => {
    try {
      const { data } = await KidService.fetchKidById(kid_id);
      setProfile(data.data[0]);
      setLoading(false);
    } catch (err) {}
  };

  let getKidExpences = async () => {
    try {
      const { data } = await ExpencesService.fetchExpencesKid(kid_id);
      setExpences(data.data);
    } catch (err) {}
  };

  let getKidPhotos = async () => {
    try {
      const { data } = await KidService.fetchPhotosByKidId({
        enrollment_id: profile?.enrollment_id,
      });
      setKidPhotos(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  let getKidDocuments = async () => {
    try {
      const { data } = await KidService.fetchDocumentsByKidId({
        enrollment_id: profile?.enrollment_id,
      });
      setKidDocuments(data.data);
    } catch (err) {
      console.log(err);
    }
  };
  if (profile.is_sponsored) {
    toast.success(`${profile.name} is already sponsored!`);
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="profile-container">
          {/* Header Section */}
          <div className="profile-header">
            <img
              src={profile.cover_url}
              alt="Background"
              className="profile-bg"
            />
            <img
              src="/img/kids-banner.png"
              className="kid-profile-banner"
              alt="kid-profile-banner"
            />

            <div className="profile-details">
              <div className="kid-profile-avatar">
                <img
                  src={profile.avatar_url || "../child/noImg.png"}
                  alt="profile-avatar"
                  className="rounded-circle"
                />
              </div>
              <div className="profile-meta flex">
                <h5 className="profile-name">{profile.name}</h5>
                <span>
                  <strong>Id:&nbsp;</strong>
                  {profile.enrollment_id}
                </span>
                <span>
                  <strong>Category:&nbsp;</strong>
                  {profile.category_name}
                </span>
              </div>
              {/* {profile.is_sponsored ? } */}

              <div className="profile-actions mt-20">
                {profile.is_sponsored ? (
                  <button className="btn btn-primary mb-3">
                    {profile.name} is Already Sponsored
                  </button>
                ) : (
                  <>
                    {!isSponsoredKid && profile.total_expenses > 0 && (
                      <button
                        onClick={() =>
                          user_id
                            ? navigate(`/checkout/${kid_id}`)
                            : navigate(`/login`)
                        }
                        className="btn btn-primary me-3 mb-3"
                      >
                        {user_id ? "Sponsor" : "Sponsor by Registering"}
                      </button>
                    )}

                    {!user_id &&
                      !isSponsoredKid &&
                      profile.total_expenses > 0 && (
                        <button
                          onClick={() => navigate(`/checkout/${kid_id}`)}
                          className="btn btn-primary mb-3 "
                        >
                          Sponsor Anonymously
                        </button>
                      )}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Profile Section */}
          <div className="profile-section container">
            <h3 className="text-align-center">Profile</h3>
            <div className="profile-card">
              <img
                src={profile.avatar_url || "../child/noImg.png"}
                alt="Profile"
                className="profile-card-pic"
              />
              <div className="profile-info">
                <p>
                  <strong>Monthly expense for {profile.name}: </strong>
                  {profile.total_expenses
                    ? profile.total_expenses
                    : profile.basic_amount}
                </p>
                <p>
                  <strong> Born: </strong>
                  {moment(profile.date_of_birth).format("YYYY")}
                </p>
                {user_id || super_admin_id ? (
                  <>
                    <p>
                      <strong>Father's Name:</strong>
                      {profile.father_name}
                    </p>

                    <p>
                      <strong>Mother's Name:</strong>
                      {profile.mother_name}
                    </p>
                  </>
                ) : null}
                <p>
                  <strong> Category:</strong>
                  {profile.category_name}
                </p>
                <p>
                  <strong> Lives In:</strong>
                  {profile.address}
                </p>
                <p>
                  {" "}
                  <strong>Bio: </strong>
                  {profile.bio}
                </p>
              </div>
            </div>
          </div>

          {/* Expense Section */}
          <div className="expense-section container">
            <h3 className="text-align-center">Expense</h3>
            <div className="expense-cards">
              <div className="expense-card basic">
                <span>Basic Expense</span>
                <p>{profile?.basic_amount}</p>
              </div>
              <div className="expense-card education">
                <span>Educational Expense</span>
                {expences?.education?.length > 0 &&
                  expences?.education.map((e) => (
                    <p>
                      {" "}
                      {e.education_name}: ₹{e.amount}
                    </p>
                  ))}
              </div>
              <div className="expense-card curriculum">
                <span>Co-Curriculum Expense</span>
                {expences?.curriculum?.length > 0 &&
                  expences?.curriculum.map((c) => (
                    <p>
                      {c.curriculum_name}: ₹{c.amount}
                    </p>
                  ))}
              </div>
              <div className="expense-card activity">
                <span>Activity Expense</span>
                {expences?.extra_curriculum?.length > 0 &&
                  expences?.extra_curriculum.map((extra) => (
                    <p>
                      {extra.extra_curriculum_name}: ₹{extra.amount}
                    </p>
                  ))}
              </div>
            </div>
          </div>

          {/* Photos and Documents Section */}
          <div className="media-section container">
            <Tabs>
              <TabList>
                <Tab className="btn">Photos</Tab>
                <Tab className="btn">Documents</Tab>
              </TabList>

              <TabPanel>
                {(user_id && isSponsoredKid) || super_admin_id ? (
                  <div className="row">
                    {kidPhotos.length > 0 ? (
                      kidPhotos.map((k) => (
                        <div className="photo-item">
                          <img
                            src={k.photo_url}
                            className="photo-img"
                            alt="kidimage"
                            style={{ cursor: "pointer" }}
                            onClick={() => setIsImgFull(k.photo_url)}
                          />
                        </div>
                      ))
                    ) : (
                      <h5>No Photos Available</h5>
                    )}
                  </div>
                ) : (
                  <ErrorMessage message={"Photos"} />
                )}
              </TabPanel>
              <TabPanel>
                {(user_id && isSponsoredKid) || super_admin_id ? (
                  <div className="document-gallery">
                    {kidDocuments.length > 0 ? (
                      kidDocuments.map((document, index) => (
                        <div key={index} className="document-item">
                          <a href={document.document_url} download>
                            <i className="fa fa-file-o"></i>&nbsp;
                            {document.title}
                          </a>
                        </div>
                      ))
                    ) : (
                      <h5>No Documents</h5>
                    )}
                  </div>
                ) : (
                  <ErrorMessage message={"Documents"} />
                )}
              </TabPanel>
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
};

export default KidProfile;
