import React, { useState, useEffect } from "react";
import decodeToken from "../../utils/decodeToken";
import SponsoredService from "../../services/sponsored";
import KidService from "../../services/kids";
import PaymentService from "../../services/payment";
import { useNavigate } from "react-router-dom";
import Card from "../kids/components/Card";
import SponseredKidCard from "../sponseredKids/components/Card";
import moment from "moment";
import NoImg from "../../assets/no-image.png";
import Loader from "../../components/Loader";
import Datatable from "../../components/Datatable";
import columnHeader from "./ColumnHeaderData";

import "./MentorProfile.css";

const MentorProfile = () => {
  let { user_id, super_admin_id, full_name, avatar_url } = decodeToken();
  const [sponsoredKids, setSponsoredKids] = useState([]);
  const [filterSponserKidsData, setFilterSponserKidsData] = useState([]);
  const [kids, setKids] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isViewAll, setIsViewAll] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    getAllSponsoredKids();
    getAllKids();
    getAllPayments();
  }, []);

  const getAllSponsoredKids = async () => {
    try {
      const { data } = await SponsoredService.fetchSponsorKidsByMentor(user_id);
      console.log("SSSSSSSSS", data);
      const uniqueKids = [
        ...new Map(data.data.map((d) => [d["kid_id"], d])).values(),
      ];

      setSponsoredKids(uniqueKids);
      setSponsoredKids(uniqueKids.filter((k) => !k.to_be_sponsor));
      //setLoading(false);
      setLoading(false);
    } catch (err) {
      setSponsoredKids([]);
      console.log(err);
    }
  };
  const onViewProfile = (id) => {
    navigate(`/kid/${id}?isSponsored=true`);
  };
  let getAllKids = async () => {
    try {
      const { data } = await KidService.fetchKids();
      setKids(data.data.filter((k) => k.is_sponsored === false));
      let statesArr = data.data
        .filter((k) => k.is_sponsored === false && k.state)
        .map((s) => s.state);
      // setStates([...new Set(statesArr)]);
      setFilterSponserKidsData(
        data.data.filter((k) => k.is_sponsored === false)
      );
      setLoading(false);
    } catch (err) {}
  };

  const getAllPayments = async () => {
    try {
      const { data } = await PaymentService.fetchPaymentById(user_id);
      setRowData(data.data);
      // setLoading(false);
    } catch (err) {
      setRowData([]);
      console.log(err);
    }
  };
  // const [loading, setLoading] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);

  const loadMoreTransactions = () => {
    setLoading(true);
    setTimeout(() => {
      setIsViewAll(true);
      setLoading(false);
    }, 2000);
  };
  const dataToShow = isViewAll ? rowData : rowData.slice(0, 5);
  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const onViewReceipt = (certificate_url) => {
    window.open(certificate_url, "_blank");
  };
  const handleViewAll = () => {
    navigate("/sponsored-kids"); // Navigate to the /sponsored-kids route
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="mentor-profile-container">
          {/* Header Section */}

          <section className="mentor-header">
            <>
              <img
                src={avatar_url ? avatar_url : NoImg}
                alt="Mentor"
                className="mentor-profile-pic"
              />
              <h1 className="mentor-profile-name">{full_name}</h1>
            </>
          </section>
          {/* Sponsored Kids Section */}
          <section className="sponsored-section">
            <h2 className="text-align-center">Stars you have Brightened</h2>
            <div className="sponsored-kids container">
              {sponsoredKids?.length > 0 ? (
                sponsoredKids
                  .filter(
                    (kid) =>
                      moment(kid.mentoring_left_date).diff(moment(), "days") <
                      30
                  )
                  .slice(0, 4) // Show only 4 items
                  .map((kid) => (
                    <SponseredKidCard
                      key={kid.id} // Make sure to use a unique key for each child
                      user_id={user_id}
                      super_admin_id={super_admin_id}
                      onViewProfile={onViewProfile}
                      data={kid}
                    />
                  ))
              ) : (
                <h5 className="alert alert-danger text-center">
                  No Sponsored Kid Found
                </h5>
              )}
            </div>
            <button className="btn btn-center" onClick={handleViewAll}>
              View All
            </button>
          </section>

          {/* Sponsor a Kid Section */}
          <section className="sponsor-section">
            <h2 className="text-align-center">
              Stars you'd love to shine a light on
            </h2>

            <div className="sponsor-kids container">
              {filterSponserKidsData?.length > 0 &&
                shuffleArray(filterSponserKidsData)
                  .slice(0, 4)
                  .map((data) => (
                    <div className="col-sm-3">
                      <Card onViewProfile={onViewProfile} data={data} />
                    </div>
                  ))}
            </div>
            <button className="btn btn-center">View All Kids</button>
          </section>

          {/* Transaction History Section */}
          <section className="transaction-history">
            <h2 className="text-align-center">Your Transaction History</h2>
            {/* <div className="transaction-controls">
          <input
            type="text"
            placeholder="Search..."
            className="transaction-search"
          />
          <select className="transaction-sort">
            <option>Sorting</option>
            <option>Date</option>
            <option>Amount</option>
          </select>
        </div> */}
            <div className="transaction-table-wrapper">
              <table className="transaction-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Transaction id</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {dataToShow.length > 0 ? (
                    dataToShow.map((data, id) => (
                      <tr key={id}>
                        <td>{data.name}</td>
                        <td>{data.amount}</td>
                        <td>{data.payment_date}</td>
                        <td>{data.transaction_id}</td>
                        <td>{data.status}</td>
                        <td>
                          <button
                            className="btn"
                            onClick={() => onViewReceipt(data.certificate_url)}
                          >
                            View Receipt
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="6"
                        className="alert alert-danger text-center"
                      >
                        No Payment History Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {!isViewAll && rowData.length > 5 && !allLoaded && (
              <button
                className="btn btn-center"
                onClick={loadMoreTransactions}
                disabled={loading}
              >
                {loading ? "Loading..." : "View All Transactions"}
              </button>
            )}
          </section>
        </div>
      )}
    </>
  );
};

export default MentorProfile;
