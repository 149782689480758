import React from "react";
import { useLocation } from "react-router-dom";
import { Row } from "react-bootstrap";
import Slider from "react-slick";
import { VscChromeClose } from "react-icons/vsc";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Arrow components for Swiper
const ArrowLeft = (props) => (
  <button {...props} className="slick-prev">
    &#9654;
  </button>
);
const ArrowRight = (props) => (
  <button {...props} className="slick-next">
    &#9664;
  </button>
);
const categoryImages = {
  orphans: "/child/category/orphans.jpeg",
  aalim_aalima: "/child/category/Alim-alima.jpeg",
  hafiz: "/child/category/Hafiz.jpeg",
  learning_centre: "/child/category/Learning.jpeg",
  communal_violence: "/child/category/communal.jpeg",
};

const Category = ({
  categoryData,
  kidsData,
  onCategoryHandler,
  activeIndex,
}) => {
  const location = useLocation();
  const settings = {
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    slidesToShow: window.innerWidth < 768 ? 1 : 4,
    slidesToScroll: window.innerWidth < 768 ? 1 : 4,

    centerMode: true,
    centerPadding: "0",
    infinite: true,
    adaptiveHeight: true,
  };
  // A function to handle category click
  const handleCategoryClick = (e, categoryId, index) => {
    onCategoryHandler(e, categoryId, index);
  };

  return (
    <div className="category-container">
      {location.pathname === "/" ? (
        <div className="category-slider-container">
          <Row className="category-slider-wrapper">
            <Slider {...settings}>
              {categoryData.length > 0 &&
                categoryData.map((category, index) => {
                  const normalizedCategory = category.category_name
                    .toLowerCase()
                    .replace(/\s+/g, "_")
                    .replace(/\//g, "_");
                  return (
                    <div
                      key={category.id}
                      className={`card ${
                        index === activeIndex ? "active" : ""
                      }`}
                      onClick={(e) =>
                        handleCategoryClick(e, category.id, index)
                      }
                    >
                      {/* <img
                      src={"./child/noImg.png"}
                      alt={category.category_name}
                      className="profile-pic"
                    /> */}
                      <img
                        src={
                          categoryImages[normalizedCategory] ||
                          "/assets/noImg.png"
                        }
                        alt={category.category_name}
                        className="profile-pic"
                      />
                      <button
                        className="btn"
                        style={{ background: category?.category_color }}
                      >
                        {category.category_name}
                      </button>
                    </div>
                  );
                })}
            </Slider>
          </Row>
        </div>
      ) : (
        <div className="categories-list text-center">
          <div className="categories">
            {/* Button for All Kids */}
            <button
              className="btn category-btn active"
              onClick={(e) => handleCategoryClick(e, null, "all")}
            >
              All Kids{" "}
              <span className="badge" style={{ background: "black" }}>
                {kidsData?.length}
              </span>
            </button>

            {/* Buttons for individual categories */}
            {categoryData.map((category, index) => (
              <button
                key={category.id}
                className={`btn category-btn ${
                  index === activeIndex ? "active" : ""
                }`}
                onClick={(e) => handleCategoryClick(e, category.id, index)}
              >
                {category.category_name}
                <span
                  className="badge"
                  style={{ background: category?.category_color }}
                >
                  {
                    kidsData.filter((kid) => kid.category_id === category.id)
                      .length
                  }
                </span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Category;
