import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import decodeToken from "../utils/decodeToken";
import { toast } from "react-toastify";
import NoImg from "../assets/no-image.png";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  let { user_id, super_admin_id, full_name, avatar_url } = decodeToken();

  const onLogout = () => {
    localStorage.removeItem("loginToken");
    toast.success("Logout successfully");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const handleClickOutside = (e) => {
      if (!e.target.closest(".user-menu")) {
        setIsDropdownOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("click", handleClickOutside); // Detect outside clicks

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("click", handleClickOutside); // Clean up
    };
  }, []);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className={`navbar ${isScrolled ? "scrolled" : ""}`}>
      <div className="navbar-container">
        <div
          className="navbar-logo"
          onClick={() => (window.location.href = "https://shaagird.org/")}
        >
          <img src="/logo.png" alt="Logo" />
        </div>
        {/* <div className="navbar-search">
          <input type="text" placeholder="Search..." />
        </div> */}
        <nav className={`navbar-links ${isMenuOpen ? "open" : ""}`}>
          <NavLink to={"/"} onClick={closeMenu}>
            Home
          </NavLink>
          <NavLink to={"/all-kids"} onClick={closeMenu}>
            Sponsor Kid
          </NavLink>
          {/* <NavLink to={"/sponsored-kids"}>Sponsored Kids</NavLink> */}
          <NavLink to={"/sponsored-kids"} onClick={closeMenu}>
            Sponsored Kids
          </NavLink>

          {user_id || super_admin_id ? (
            <div
              className="user-menu"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <img
                src={avatar_url ? avatar_url : NoImg}
                alt="User Avatar"
                className="user-avatar"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              />
              <span className="user-name">{full_name}</span>
              {isDropdownOpen && (
                <div className="dropdown-menu">
                  <a href="/mentor-profile-new">Profile</a>
                  {/* <a href="/notifications">Notifications</a> */}
                  <a href="/mentor-profile">Setting</a>
                  <a href="/logout" onClick={onLogout}>
                    Logout
                  </a>
                </div>
              )}
            </div>
          ) : (
            <a href="/login">Login</a>
          )}
          <button
            className="donate-btn btn"
            onClick={() =>
              (window.location.href = "https://donate.shaagird.org/")
            }
          >
            Donate
          </button>
        </nav>
        <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
