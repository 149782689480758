import moment from "moment";
import React from "react";
import { FaRegBookmark } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";
import { useLocation } from "react-router-dom";

const SponseredKidCard = ({
  data,
  onViewProfile,
  user_id,
  super_admin_id,
  updateKidToBeSponsor,
}) => {
  let {
    avatar_url,
    amount,
    for_months,
    name,
    date_of_birth,
    join_date,
    address,
    category_name,
    mentored_on_date,
    full_name,
    category_color,
    mentoring_left_date,
    id,
    kid_id,
  } = data;
  const location = useLocation();

  // const expireSponsoredDate = ()=>{
  //   if(moment(new Date()).format("DD-MM-YYYY") 	=== moment(mentoring_left_date).format("DD-MM-YYYY")){
  //     return true
  //   }
  //   else{
  //     return false
  //   }
  // }
  const isLessThanOneDay = moment(mentoring_left_date).isBefore(
    moment().add(1, "days")
  );
  const daysLeft = moment(mentoring_left_date).diff(moment(), "days");
  const message =
    daysLeft < 30 ? `${daysLeft} days left` : "More than 30 days left";

  return (
    <>
      {location.pathname === "/" ? (
        daysLeft < 30 ? (
          <div className="card mb-3">
            <div class="card-header">
              <FiShare2 className="share-icon" />
              <FaRegBookmark className="bookmark-icon" />
            </div>
            <img
              className="profile-pic"
              src={avatar_url || "./child/noImg.png"}
              alt=""
            />
            {/* {to_be_sponsor && <span className="ribbon">Priority</span>} */}
            <div className="card-body">
              <div className="align-items-center">
                <div className="name">{name}</div>
                <p class="amount">₹ 1500</p>
                <p class="time-left">
                  <span>⏱️</span> {message} days left
                </p>
                <button
                  class="sponsor-btn btn"
                  onClick={() => onViewProfile(kid_id)}
                >
                  Sponsor {name}
                </button>
              </div>
            </div>
          </div>
        ) : null
      ) : location.pathname === "/mentor-profile-new" ? (
        <div className="col-sm-3">
          <div className="card mb-3">
            <div class="card-header">
              <FiShare2 className="share-icon" />
              <FaRegBookmark className="bookmark-icon" />
            </div>
            <img
              className="profile-pic"
              src={avatar_url || "./child/noImg.png"}
              alt=""
            />
            {/* {to_be_sponsor && <span className="ribbon">Priority</span>} */}
            <div className="card-body">
              <div className="align-items-center">
                <div className="name">{name}</div>
                <p class="amount">₹ 1500</p>
                <p class="time-left">
                  <span>⏱️</span> {message} days left
                </p>
                <button
                  class="sponsor-btn btn"
                  onClick={() => onViewProfile(kid_id)}
                >
                  Sponsor {name}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card mb-3">
          <div className="kid-img">
            <img
              className="profile-pic"
              src={avatar_url || "./child/noImg.png"}
              alt=""
            />
          </div>
          <div className="card-body">
            <div className="name text-center text-capitalize">
              <h5>
                <b>{name}</b>
                <br />
                <b
                  className="badge"
                  style={{ fontSize: 12, background: category_color }}
                >
                  {category_name}
                </b>
                <br />
                <span style={{ fontSize: 12 }} className="text-muted">
                  Mentor
                </span>{" "}
                <b style={{ fontSize: 12 }}>
                  {full_name ? full_name : "Anonymous"}
                </b>
              </h5>
            </div>
            <div style={{ fontSize: 12 }} className="d-flex align-items-center">
              <div>
                <span className="text-muted">Mentoring Date</span> <br />
                <b>{moment(mentored_on_date).format("DD-MM-YYYY")}</b>
              </div>
              <div className="ms-auto">
                {" "}
                <span className="text-muted">Expire Mentoring Date</span> <br />
                <b>{moment(mentoring_left_date).format("DD-MM-YYYY")}</b>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div style={{ fontSize: 12 }}>
                <b className="badge badge-info" style={{ fontSize: 12 }}>
                  {for_months} Months
                </b>
                <br />
              </div>
              <div className="mt-1 ms-auto">
                <b className="badge badge-info" style={{ fontSize: 12 }}>
                  ₹{amount}
                </b>
                <br />
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div
                className="view-profile"
                style={{ color: category_color, cursor: "pointer" }}
                onClick={() => onViewProfile(kid_id)}
              >
                View Profile
              </div>
              {super_admin_id && (
                <div
                  className={`btn ms-auto ${isLessThanOneDay ? "btn-red" : ""}`}
                  onClick={() => updateKidToBeSponsor(kid_id, name)}
                >
                  Move To Sponsor
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SponseredKidCard;
