import React, { useState, useEffect, useRef } from "react";
import { TextField, Button } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import onRazorpay from "../checkout/RazorPay";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import KidService from "../../services/kids";
import Noimg from "../../assets/no-image.png";
import { toast } from "react-toastify";
const validationSchema = yup.object({
  mentor_name: yup.string("Enter name").required("Name is required"),
  email: yup.string("Enter email").email().required("Email is required"),
  anonymous_mobile_no: yup
    .number("Enter Mobile")
    .required("Mobile is required"),
});
let receipt_no = Math.floor(100000000 + Math.random() * 900000000);

function Anonymous() {
  const location = useLocation();
  const for_months = new URLSearchParams(location.search).get("for_months");
  let { kid_id } = useParams();
  let [sponsorData, setSponsorData] = useState({});

  const formik = useFormik({
    initialValues: {
      mentor_name: "",
      email: "",
      address: "",
      pan_number: "",
      anonymous_mobile_no: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let restParms = {
        receipt_no,
        donation_by: "Paid Online",
        payment_date: new Date()?.toLocaleDateString(),
      };
      let spData = { ...sponsorData, ...values, ...restParms };
      onRazorpay(spData);
    },
  });
  useEffect(() => {
    getKid();
  }, [kid_id]);

  let getKid = async () => {
    try {
      const { data } = await KidService.fetchKidById(kid_id);
      console.log("aaaaaaaa", data);
      let spData = {
        kid_name: data.data[0].name,
        kid_id: data.data[0].id,
        amount: data.data[0].total_expenses * for_months,
        avatar: data.data[0].avatar_url,
        is_anonymous: false,
        category_id: data.data[0].category_id,
        for_months: for_months,
        is_active: false,
      };
      setSponsorData(spData);
    } catch (err) {}
  };

  return (
    <div className="anonymous-main-container">
      <div className="profile-bg">
        <img src="/img/profile-bg.png" alt="profile-bg" />
      </div>
      <div className="container my-3">
        <h3 className="heading text-align-center">
          Please Provide Your Information
        </h3>

        <div className="row">
          <div className="col-md-3">
            <div className="card">
              <div className="card-body text-start">
                <img
                  src={sponsorData.avatar || Noimg}
                  alt="kid-profile"
                  className="rounded-circle"
                />
                <h3 style={{ padding: "20px 0" }}>Donation Summary</h3>
                <div
                  className="mb-1"
                  style={{
                    color: "#02b8ce",
                    fontWeight: "600",
                  }}
                >
                  {sponsorData.kid_name}{" "}
                </div>
                Regular Donation
                <div
                  className="mt-1"
                  style={{ fontSize: 12, color: "#888", fontWeight: "700" }}
                >
                  {receipt_no}
                </div>
                <div className="text-end" style={{ color: "#fdc513" }}>
                  ₹{sponsorData.amount}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-9">
            <div className="card">
              <div className="card-body text-start">
                <div className="row">
                  <div className="col-md-12">
                    <label for="m2s_firstname" class="form-label">
                      Full Name
                    </label>
                    <TextField
                      fullWidth
                      id="mentor_name"
                      name="mentor_name"
                      value={formik.values.mentor_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      margin="dense"
                      size="small"
                      error={
                        formik.touched.mentor_name &&
                        Boolean(formik.errors.mentor_name)
                      }
                      helperText={
                        formik.touched.mentor_name && formik.errors.mentor_name
                      }
                    />
                  </div>
                  <div className="col-md-12">
                    <label for="m2s_Email" class="form-label">
                      Email address
                    </label>

                    <TextField
                      fullWidth
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      margin="dense"
                      size="small"
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    <small className="small-fonts">
                      We'll never share your email with anyone else.
                    </small>
                  </div>
                  <div className="col-md-12">
                    <label for="m2s_mobile" class="form-label">
                      Mobile No.
                    </label>

                    <TextField
                      fullWidth
                      name="anonymous_mobile_no"
                      value={formik.values.anonymous_mobile_no}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      margin="dense"
                      size="small"
                      error={
                        formik.touched.anonymous_mobile_no &&
                        Boolean(formik.errors.anonymous_mobile_no)
                      }
                      helperText={
                        formik.touched.anonymous_mobile_no &&
                        formik.errors.anonymous_mobile_no
                      }
                    />
                    <small className="small-fonts">
                      We'll never share your number with anyone else.
                    </small>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <label for="m2s_pan" class="form-label my-2">
                      PAN Card No.
                    </label>
                    <TextField
                      fullWidth
                      id="pan_number"
                      name="pan_number"
                      value={formik.values.pan_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      margin="dense"
                      size="small"
                    />
                    <small className="small-fonts">
                      Enter PAN card number if you need 80g receipt
                    </small>
                  </div>
                  <div className="col-md-12">
                    <label for="m2s_address" class="form-label">
                      Address
                    </label>
                    <TextField
                      fullWidth
                      id="address"
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      margin="dense"
                      size="small"
                      multiline
                      rows={2}
                      // error={
                      //   formik.touched.address && Boolean(formik.errors.address)
                      // }
                      // helperText={formik.touched.address && formik.errors.address}
                    />

                    <small className="small-fonts">
                      Your address will be private and not be shared with anyone
                      else.
                    </small>
                  </div>
                </div>
                <div className="text-end">
                  <button onClick={formik.handleSubmit} className="btn">
                    Make Donation
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Anonymous;
