import React, { useState } from "react";
import OtpInput from "react-otp-input";
import UserService from "../../services/user";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

const VerifyOtp = ({ setIsOpen, modalIsOpen, email, setShowRegister }) => {
  const [otp, setOtp] = useState("");

  const navigate = useNavigate();
  const onSubmit = async () => {
    if (otp) {
      try {
        let { data } = await UserService.verifyOtp({ otp, email });
        if (data.message) {
          toast.success(data.message);
          navigate("/login");
          setIsOpen(false);
        }
      } catch (err) {
        toast.error("OTP is not correct");
      }
    } else {
      toast.error("Please enter the otp");
    }
  };

  const onResend = async () => {
    if (email) {
      try {
        let { data } = await UserService.resendOtp({ email });
        if (data.message) {
          toast.success(data.message);
        }
      } catch (err) {
        toast.error("Something is went wrong!");
        console.log(err);
      }
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(40%, -50%)",
    },
    "@media screen and (max-width: 767px)": {
      transform: "translate(-48%, 16%)",
    },
  };
  function closeModal() {
    setIsOpen(false);
  }
  const handleCreateAccountClick = () => {
    setShowRegister(true);
  };

  return (
    // <div>
    //   <Modal
    //     isOpen={modalIsOpen}
    //     onAfterOpen={() => {}}
    //     onRequestClose={closeModal}
    //     style={customStyles}
    //     contentLabel="Example Modal"
    //   >
    //     <h6 className="text-center otp-title">Plese enter your otp</h6>
    //     <OtpInput
    //       value={otp}
    //       onChange={setOtp}
    //       numInputs={4}
    //       renderSeparator={<span>-</span>}
    //       renderInput={(props) => <input {...props} />}
    //       inputStyle={{ width: "4rem", height: 40 }}
    //     />
    //     <div className="text-center mt-2">
    //       <button className="btn btn-secondary me-3" onClick={onResend}>
    //         Resend
    //       </button>
    //       <button className="btn btn-primary" onClick={onSubmit}>
    //         Submit
    //       </button>
    //     </div>
    //   </Modal>
    // </div>
    <div className="login-form">
      <img src="/logo.png" alt="Logo" className="logo" />
      <h1 className="text-align-center">Welcome to Shaagird</h1>
      <h2 className="text-align-center">OTP</h2>
      {/* <form className="login-form"> */}
      <label>
        Enter OTP
        <input
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          numInputs={4}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={{ width: "4rem", height: 40 }}
          placeholder="Enter Code"
        />
      </label>
      <a className="small-fonts">Resend OTP</a>

      <button type="submit" className="btn-connect btn" onClick={onSubmit}>
        Verify
      </button>
      {/* </form> */}

      <p onClick={handleCreateAccountClick} className="create-account">
        New User? Create Account
      </p>
    </div>
  );
};

export default VerifyOtp;
