import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import UserService from "../../services/user";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const LoginForm = ({ setShowRegister }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState("mentor");
  // const [showRegister, setShowRegister] = useState(false);

  const validationSchema = yup.object({
    email: yup.string("Enter email").email().required("Email is required"),
    password: yup.string("Enter password").required("Password is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const loginData =
          user === "mentor"
            ? await UserService.loginUser(values)
            : await UserService.superAdminLogin(values);

        if (loginData.data.token) {
          localStorage.setItem("loginToken", loginData.data.token);
          toast.success("Login successfully");
          navigate("/");
        } else if (!loginData.data.is_verified) {
          toast.success(loginData.data.message);
          // Trigger OTP modal if needed
        }
      } catch (err) {
        toast.error("Please check your credentials");
      }
    },
  });

  // Handler for switching to account creation page
  // const handleCreateAccountClick = () => {
  //   navigate("/register");
  // };
  const handleCreateAccountClick = () => {
    setShowRegister(true);
  };

  return (
    <div className="login-form">
      <img src="/logo.png" alt="Logo" className="logo" />
      <h1 className="text-align-center">Welcome to Shaagird</h1>
      <h2 className="text-align-center">Sign-In</h2>
      <form onSubmit={formik.handleSubmit} className="login-form">
        <label>
          Username/Email
          <input
            type="email"
            placeholder="username@gmail.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="email"
            required
          />
          {formik.touched.email && formik.errors.email && (
            <div className="error">{formik.errors.email}</div>
          )}
        </label>
        <label>
          Password
          <input
            type="password"
            placeholder="************"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="password"
            required
          />
          {formik.touched.password && formik.errors.password && (
            <div className="error">{formik.errors.password}</div>
          )}
        </label>

        <div className="user-selection">
          <label>
            <input
              type="radio"
              name="user"
              value="mentor"
              checked={user === "mentor"}
              onChange={() => setUser("mentor")}
            />
            Mentor
          </label>
          <label>
            <input
              type="radio"
              name="user"
              value="superAdmin"
              checked={user === "superAdmin"}
              onChange={() => setUser("superAdmin")}
            />
            Super Admin
          </label>
        </div>

        <button type="submit" className="btn-connect btn">
          Connect
        </button>
      </form>

      <p onClick={handleCreateAccountClick} className="create-account">
        New User? Create Account
      </p>
    </div>
  );
};

export default LoginForm;
