import React, { useState, useEffect } from "react";
import UserService from "../../services/user";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import { Link } from "react-router-dom";
import VerifyOtp from "../otp/otp";
import Backdrop from "../../components/Backdrop";

const Register = ({ setShowRegister, onRegisterSuccess }) => {
  const navigate = useNavigate();
  const validationSchema = yup.object({
    full_name: yup.string("Enter fullname").required("Fullname is required"),
    mobile_no: yup
      .string("Enter Mobile number")
      .required("Mobile number is required"),
    email: yup.string("Enter email").email().required("Email is required"),
    password: yup.string("Enter Password").required("Password is required"),
  });

  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  // const [modalIsOpen, setIsOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  const formik = useFormik({
    initialValues: {
      full_name: "",
      password: "",
      mobile_no: "",
      email: "",
      role: "Mentor",
      gender: "",
      avatar_url: "",
      pan_no: "",
      addhar_no: "",
      address: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      date_of_birth: new Date(),
      nationality: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setBackdrop(true);
      values.country = countryId;
      values.state = stateId;
      values.city = cityId;
      try {
        let { status } = await UserService.register(values);
        if (status) {
          toast.success("OTP sent to your email, kindly check and verify");
          onRegisterSuccess(values.email);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || "Something went wrong!");
      }
      setBackdrop(false);
    },
  });

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);

  const onChangeCountry = (e) => {
    setCountryId(e.target.value);
    GetState(parseInt(e.target.value)).then((result) => {
      setStateList(result);
    });
  };

  const onChangeState = (e) => {
    setStateId(e.target.value);
    GetCity(countryId, e.target.value).then((result) => {
      setCityList(result);
    });
  };
  const handleBackToLogin = () => {
    setShowRegister(false);
  };

  return (
    <div className="register-form-container">
      <img src="/logo.png" alt="Logo" className="logo" />
      <h1 className="text-align-center">Welcome to Shaagird</h1>
      <h2 className="text-align-center">Create Account</h2>
      <div className="card-body">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-sm-6">
              <div class="form-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Full Name"
                  name="full_name"
                  value={formik.values.full_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="small-fonts">
                  Give us your name; we promise not to misspell it in emails.
                </small>
                <br />
                <small className="text-danger">
                  {formik.touched.full_name && formik.errors.full_name}
                </small>
              </div>
            </div>
            <div className="col-sm-6">
              <div class="form-group mb-3">
                <input
                  class="form-control"
                  placeholder="Email Address"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="small-fonts">We promise no spam!</small>
                <br />
                <small className="text-danger">
                  {formik.touched.email && formik.errors.email}
                </small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div class="form-group mb-3">
                <input
                  class="form-control"
                  placeholder="Mobile"
                  name="mobile_no"
                  value={formik.values.mobile_no}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="small-fonts">
                  We promise not to text you during your nap time!
                </small>
                <br />
                <small className="text-danger">
                  {formik.touched.mobile_no && formik.errors.mobile_no}
                </small>
              </div>
            </div>
            <div className="col-sm-6">
              <div class="form-group mb-3">
                <select
                  className="form-control"
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div class="form-group mb-3">
                <input
                  class="form-control"
                  placeholder="PAN Card Number"
                  name="pan_no"
                  value={formik.values.pan_no}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="small-fonts">
                  Enter PAN card number if you need 80(G) receipt{" "}
                </small>
                <br />
                <small className="text-danger">
                  {formik.touched.pan_no && formik.errors.pan_no}
                </small>
              </div>
            </div>

            <div className="col-sm-6">
              <div class="form-group mb-3">
                <input
                  class="form-control"
                  placeholder="Aadhaar Number"
                  name="addhar_no"
                  value={formik.values.addhar_no}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="small-fonts">
                  Your Aadhar Number is safe with us.
                </small>
                <br />
                <small className="text-danger">
                  {formik.touched.addhar_no && formik.errors.addhar_no}
                </small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 mb-3">
              <select
                className="form-control"
                name="nationality"
                value={formik.values.nationality}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Nationality</option>
                <option value="Indian">Indians</option>
              </select>
              <small className="small-fonts">
                Shaagird is not FCRA-registered; only Indians can sponsor.
              </small>
            </div>
            <div className="col-sm-6 mb-3">
              <select
                className="form-control"
                name="country"
                value={countryId}
                onChange={onChangeCountry}
              >
                <option value="">Select Country</option>
                {countriesList?.map((item, index) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
              <small className="small-fonts">
                Your country of residence: the place where your IP address
                belongs to.
              </small>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6  mb-3">
              <select
                className="form-control"
                name="state"
                value={stateId}
                onChange={(e) => {
                  setStateId(e.target.value);
                  GetCity(+countryId, +e.target.value).then((result) => {
                    setCityList(result);
                  });
                }}
              >
                <option value="">Select State</option>
                {stateList?.map((item, index) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
              <small className="small-fonts">
                Don't worry, we won't ask you to draw it on a map. Unless you
                want to.
              </small>
            </div>
            <div className="col-sm-6  mb-3">
              <select
                className="form-control"
                name="city"
                value={cityId}
                onChange={(e) => {
                  setCityId(e.target.value);
                }}
              >
                <option value="">Select City</option>
                {cityList?.map((item, index) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div class="form-group mb-3">
                <input
                  class="form-control"
                  placeholder="Pincode"
                  name="pincode"
                  value={formik.values.pincode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="small-fonts">
                  Your Pincode will be private and will not be shared with
                  anyone else.
                </small>
                <br />
                <small className="text-danger">
                  {formik.touched.pincode && formik.errors.pincode}
                </small>
              </div>
            </div>
            <div className="col-sm-6">
              <div class="form-group mb-3">
                <input
                  class="form-control"
                  placeholder="Password"
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="small-fonts">
                  Choose a password that even Sherlock Holmes couldn't crack.
                </small>
                <br />
                <small className="text-danger">
                  {formik.touched.password && formik.errors.password}
                </small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <textarea
                placeholder="Address"
                class="form-control"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
              <small className="small-fonts">
                We promise not to send pizza to your address…unless you want us
                to.
              </small>
              <br />
            </div>
          </div>

          <button type="submit" class="btn mt-3 w-100">
            Register
          </button>
          <p className="mt-3 mb-0">
            Already have an account{" "}
            <Link onClick={handleBackToLogin}>Sign In </Link>
          </p>
        </form>
      </div>
      {/* {modalIsOpen && (
        <VerifyOtp
          email={formik.values.email}
          setIsOpen={setIsOpen}
          modalIsOpen={modalIsOpen}
        />
      )}
      {backdrop && <Backdrop />} */}
    </div>
  );
};

export default Register;
