import React, { useEffect, useState } from "react";
import Card from "./components/Card";
import { VscChromeClose } from "react-icons/vsc";
import { Col, Container, Form, Row } from "react-bootstrap";
import Category from "./components/Category";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import RangeSlider from "react-bootstrap-range-slider";
import KidService from "../../services/kids";
import CategoryService from "../../services/categories";
import Loader from "../../components/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper.min.css";
import { EffectCoverflow } from "swiper";

const SponsorKids = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate();
  const [kids, setKids] = useState([]);
  const [states, setStates] = useState([]);
  const [stateVal, setStateVal] = useState("");
  const [genderVal, setGenderVal] = useState("");
  const [budgetVal, setBudgetVal] = useState(0);
  const [categories, setCategories] = useState([]);
  const [filterSponserKidsData, setFilterSponserKidsData] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllKids();
    getAllCategories();
  }, []);

  let getAllKids = async () => {
    try {
      const { data } = await KidService.fetchKids();
      setKids(data.data.filter((k) => k.is_sponsored === false));
      let statesArr = data.data
        .filter((k) => k.is_sponsored === false && k.state)
        .map((s) => s.state);
      setStates([...new Set(statesArr)]);
      setFilterSponserKidsData(
        data.data.filter((k) => k.is_sponsored === false)
      );
      setLoading(false);
    } catch (err) {}
  };
  let getAllCategories = async () => {
    try {
      const { data } = await CategoryService.fetchCategories();
      setCategories(data.data);
    } catch (err) {}
  };

  const onCategory = (e, category_id, index) => {
    if (category_id) {
      setActiveIndex(index);
      // e.target.parentElement.firstChild.className = "";
      setFilterSponserKidsData(
        kids.filter(
          (d) =>
            d.category_id === category_id &&
            (genderVal ? d.gender == genderVal : d.gender) &&
            (stateVal ? d.state == stateVal : d.state) &&
            (budgetVal
              ? parseInt(budgetVal) >= d.total_expenses
              : d.total_expenses)
        )
      );
      setCategoryId(category_id);
    } else {
      e.target.classList.add("active");
      // e.target.className = "active";
      setActiveIndex(null);
      setFilterSponserKidsData(kids);
      setCategoryId(null);
      setShowFilter(false);
    }
  };

  const onViewProfile = (id) => {
    navigate(`/kid/${id}`);
  };

  const onChangeFilter = (e) => {
    let { value, name, checked } = e.target;
    if (name === "gender") {
      setGenderVal(value);
      if (stateVal && value && budgetVal) {
        setFilterSponserKidsData(
          kids.filter(
            (k) =>
              k.gender == value &&
              k.state == stateVal &&
              (categoryId ? k.category_id == categoryId : k.category_id) &&
              k.total_expenses >= budgetVal
          )
        );
      } else if (stateVal && value) {
        setFilterSponserKidsData(
          kids.filter(
            (k) =>
              k.gender == value &&
              k.state == stateVal &&
              (categoryId ? k.category_id == categoryId : k.category_id)
          )
        );
      } else {
        setFilterSponserKidsData(
          kids.filter(
            (k) =>
              k.gender == value &&
              (categoryId ? k.category_id == categoryId : k.category_id)
          )
        );
      }
    } else if (name === "state") {
      setStateVal(value);
      if (genderVal && value && budgetVal) {
        setFilterSponserKidsData(
          kids.filter(
            (k) =>
              k.state == value &&
              k.gender == genderVal &&
              (categoryId ? k.category_id == categoryId : k.category_id) &&
              parseInt(budgetVal) >= k.total_expenses
          )
        );
      } else if (genderVal && value) {
        setFilterSponserKidsData(
          kids.filter(
            (k) =>
              k.state == value &&
              k.gender == genderVal &&
              (categoryId ? k.category_id == categoryId : k.category_id)
          )
        );
      } else {
        if (!value) {
          setFilterSponserKidsData(kids);
        } else {
          setFilterSponserKidsData(
            kids.filter(
              (k) =>
                k.state == value ||
                (k.gender == genderVal &&
                  (categoryId ? k.category_id == categoryId : k.category_id))
            )
          );
        }
      }
    } else if (name === "budget") {
      setBudgetVal(value);
      if (genderVal && value && stateVal) {
        setFilterSponserKidsData(
          kids.filter(
            (k) =>
              parseInt(value) >= k.total_expenses &&
              k.gender == genderVal &&
              k.state == stateVal &&
              (categoryId ? k.category_id == categoryId : k.category_id)
          )
        );
      } else if (genderVal && value) {
        setFilterSponserKidsData(
          kids.filter(
            (k) =>
              parseInt(value) >= k.total_expenses &&
              k.gender == genderVal &&
              (categoryId ? k.category_id == categoryId : k.category_id)
          )
        );
      } else if (stateVal && value) {
        setFilterSponserKidsData(
          kids.filter(
            (k) =>
              parseInt(value) >= k.total_expenses &&
              k.state == stateVal &&
              (categoryId ? k.category_id == categoryId : k.category_id)
          )
        );
      } else {
        setFilterSponserKidsData(
          kids.filter(
            (k) =>
              parseInt(value) >= k.total_expenses ||
              k.gender == genderVal ||
              (k.state == stateVal &&
                (categoryId ? k.category_id == categoryId : k.category_id))
          )
        );
      }
    }
  };

  return (
    <>
      <>
        <Navbar />

        {loading ? (
          <Loader />
        ) : (
          <section className="category-container" marginTop="100px">
            <div className="profile-bg">
              <img src="/img/profile-bg.png" alt="profile-bg" />
            </div>
            <div className="container my-3" style={{ position: "relative" }}>
              <h3
                className="heading text-align-center"
                style={{ margin: "30px 0" }}
              >
                Every child deserves the opportunity to grow
              </h3>
              <div className="row">
                <Category
                  activeIndex={activeIndex}
                  kidsData={kids}
                  onCategoryHandler={onCategory}
                  categoryData={categories}
                />

                <div className="col-12 position-relative">
                  <button
                    className="btn filter-btn"
                    onClick={() => {
                      setShowFilter(!showFilter);
                      setBudgetVal(0);
                      showFilter &&
                        setFilterSponserKidsData(
                          kids.filter((k) => k.is_sponsored === false)
                        );
                      !showFilter && setBudgetVal(0);
                      !showFilter && setGenderVal("");
                      !showFilter && setStateVal("");
                    }}
                  >
                    <i class="fa fa-filter" aria-hidden="true"></i>{" "}
                    {showFilter && (stateVal || genderVal || budgetVal)
                      ? "Reset Filter"
                      : showFilter
                      ? "Hide"
                      : "Show"}
                  </button>
                </div>
              </div>

              <div className="row">
                {showFilter ? (
                  <div className="col-sm-3 mb-3">
                    <div className="border p-3">
                      <Row>
                        <Col sm="12" className="mb-3">
                          <div>
                            <h6>Gender</h6>
                            <span className="d-flex">
                              <Form.Check
                                type="radio"
                                name="gender"
                                value="Male"
                                className="me-2"
                                onChange={onChangeFilter}
                              />{" "}
                              Male
                            </span>
                            <span className="d-flex">
                              <Form.Check
                                type="radio"
                                name="gender"
                                value="Female"
                                className="me-2"
                                onChange={onChangeFilter}
                              />{" "}
                              Female
                            </span>
                          </div>
                        </Col>
                        <Col sm="12" className="mb-3">
                          <div>
                            <h6>State</h6>
                            <div>
                              <Form.Select
                                name="state"
                                onChange={onChangeFilter}
                              >
                                <option value="">Select State</option>
                                {states?.length > 0 &&
                                  states?.map((state) => (
                                    <option value={state}>{state}</option>
                                  ))}
                              </Form.Select>
                            </div>
                          </div>
                        </Col>
                        <Col sm="12" className="mb-3">
                          <div className="text-muted">
                            <h6>Budget</h6>
                            <RangeSlider
                              min={0}
                              max={5000}
                              name="budget"
                              value={budgetVal}
                              onChange={onChangeFilter}
                              tooltipPlacement="top"
                              style={{ width: "100%" }}
                            />
                            <b>₹{budgetVal}</b>
                            <b style={{ float: "right" }}>₹5000</b>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : null}
                <div className={`${showFilter ? "col-sm-9" : "col-sm-12"}`}>
                  <div className="row">
                    {filterSponserKidsData?.length > 0 &&
                      filterSponserKidsData.map((data) => (
                        <div
                          className={`${showFilter ? "col-sm-4" : "col-sm-3"}`}
                        >
                          <Card onViewProfile={onViewProfile} data={data} />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    </>
  );
};

export default SponsorKids;
