import React from "react";

const ErrorMessage = ({ noData, message }) => {
  return (
    <div class="errorModule">
      <div class="errorIcon">
        <i class="fa fa-unlink"></i>
      </div>
      {noData ? (
        <div class="errorMsg">No Kids found in {noData}</div>
      ) : (
        <div class="errorMsg">To See {message} Login as Mentor</div>
      )}
    </div>
  );
};

export default ErrorMessage;
