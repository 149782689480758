import React, { useState } from "react";
import LoginForm from "./LoginForm";
import RegisterForm from "../register/RegisterForm";
import VerifyOtp from "../otp/otp";
import "./LoginRegister.css";

const LoginRegister = () => {
  const [showRegister, setShowRegister] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [email, setEmail] = useState("");

  const handleCreateAccountClick = () => {
    setShowRegister(true);
  };

  const handleBackToLogin = () => {
    setShowRegister(false);
  };

  const handleOtpScreen = (registeredEmail) => {
    setEmail(registeredEmail);
    setShowRegister(false);
    setShowOtp(true);
  };

  return (
    <div className="login-register-container">
      {/* Gradient Background */}
      <div
        className={`gradient-bg ${showRegister || showOtp ? "slide-down" : ""}`}
      />

      {/* Form Section */}
      {!showRegister && !showOtp ? (
        <div className="login-form-container">
          <LoginForm setShowRegister={setShowRegister} />
        </div>
      ) : showRegister ? (
        <RegisterForm
          setShowRegister={setShowRegister}
          onRegisterSuccess={handleOtpScreen}
        />
      ) : (
        <div className="otp-form-container">
          <VerifyOtp
            setIsOpen={setShowOtp}
            modalIsOpen={showOtp}
            setShowRegister={setShowRegister}
            email={email}
          />
        </div>
      )}
    </div>
  );
};

export default LoginRegister;
